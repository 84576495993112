import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { identityApiRef } from '@backstage/core-plugin-api';
import { GraphQLVoyagerEndpoints, graphQlVoyagerApiRef } from '@backstage/plugin-graphql-voyager';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: graphQlVoyagerApiRef,
    deps: { identityApi: identityApiRef },
    factory: () => {
      return GraphQLVoyagerEndpoints.from([
        {
          id: `graphql-voyager-endpoint-id`,
          title: 'endpoint-title',
          introspectionErrorMessage:
            'Unable to perform introspection, make sure you are on the correct environment.',
          introspection: async (query: any) => {

            const endpoint = 'https://spacex-production.up.railway.app/';
            const res = await fetch(endpoint, {
              method: 'POST',
              body: JSON.stringify({ query }),
              headers: {
                'Content-Type': 'application/json'
              },
            });

            return res.json();
          },
          voyagerProps: {
            hideDocs: true,
          },
        },
      ]);
    },
  }),
];
